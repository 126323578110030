import React, {useEffect, useState} from "react";
import classes from "./ActionsList.module.css"
import {Button} from "primereact/button";
import {isAuthorized} from "../../../api/authorization/authorization-service";
import {isClosed} from "../../../api/dataCollection/dataCollection-status";
import CoordinationDialog from "../../DataCollectionDetails/Coordination/CoordinationDialog";
import {getCoordinations} from "../../../api/Coordination/coordination-service";

const Actions = (props) => {
    const [mnData, setMnData] = useState(null);
    const [isCoordinationButtonVisible, setIsCoordinationButtonVisible] = useState(false);
    const publishAuthorizationRoleVisibilityBtn = isAuthorized("AR013")
        ? (<Button className={classes.actionBtn}
                   disabled={!props.isAllDataEmpty && ((!isAuthorized("AR08") && isClosed(props.dataCollection.status)) || props.btnPublishVisibility || props.statusLV4||props.isValidation5InProgress)}
                   onClick={props.onPublishClick}
                   label={props.publishBtnLabel}
                   loading={props.publishBtnLabel === props.persistingLabel}
            />
        ) : null

    const validateAllAuthorizationRoleVisibilityBtn = isAuthorized("AR013")
        ? (
            <Button className={classes.actionBtn}
                    label="Validate all Data"
                    loading={props.statusLV4}
                    visible={!props.isAllDataEmpty}
                    disabled={(!isAuthorized("AR08") && isClosed(props.dataCollection.status)) || props.validationButtonDisabled || props.marketNodeDataIsPublishing||props.isValidation5InProgress}
                    onClick={props.onValidateAllDataClick}/>
        ) : null


    useEffect(() => {
        getCoordinations(props.marketNodeData?.id).then(r => {
            if (r.content.length > 0) {
                setIsCoordinationButtonVisible(true)
            }
        })
    }, []);


    const coordinationButton = isAuthorized("AR013") && isCoordinationButtonVisible
        ? (
            <Button icon="pi pi-table" className={classes.actionBtn} onClick={() => {
                props.setIsCoordinationPopupVisible(true);
                setMnData(props.marketNodeData);
            }}></Button>
        ) : null
    return (
        <div className="grid p-0 m-0">
            <div className="col-8"/>
            <div className="col-4 flex flex-row-reverse flex-wrap card-container ">
                {publishAuthorizationRoleVisibilityBtn}
                {validateAllAuthorizationRoleVisibilityBtn}
                {coordinationButton}
            </div>
            <CoordinationDialog
                isCoordinationPopupVisible={props.isCoordinationPopupVisible}
                marketNodeData={mnData}
                dcId={props.dataCollection.id}
                hidePopup={() => props.setIsCoordinationPopupVisible(false)}></CoordinationDialog>
        </div>

    )

}

export default Actions;
