import React from "react";
import CategoryData from "./CategoryData/CategoryData";
import {ScrollPanel} from 'primereact/scrollpanel';
import classes from './CategoriesData.module.css'

const CategoriesData = (props) => {


    return (
        <ScrollPanel className={classes.scroll} style={{width: '100%'}}>
            {props.categoriesProps.map((e, i) => (
                <div className="mb-3" key={`cat-${i + 1}`}>
                    <CategoryData
                        marketNode={e['marketNodeDTO']}
                        category={e}
                        index={i}
                        modelPath={props.modelPath}
                        statusLV4={props.statusLV4}
                        isValidation5InProgress={props.isValidation5InProgress}
                        dataCollection={props.dataCollection}
                        setValidationButtonDisabled={props.setValidationButtonDisabled}
                        categoriesProps={props.categoriesProps}
                        findByDCAndMN={props.findByDCAndMN}
                        marketNodeDataIsPublishing={props.marketNodeDataIsPublishing}

                    />
                </div>
            ))}
        </ScrollPanel>
    )
}

export default CategoriesData;
