import React, {useEffect, useRef, useState} from "react";
import {uploadFile} from "../../../../../api/file/upload-service";
import Spinner from "./Spinner/Spinner";
import UploadButton from "./UploadButton/UploadButton";
import {showError} from "../../../../../api/notification/ToastManager";
import keycloak from "../../../../../keycloak";
import {setNoDataToProvide} from "../../../../../api/Category/category-service";

const DataUpload = (props) => {
    const fileUploadRef = useRef(null);
    const [fileName, setFileName] = useState(props.category.filename);
    const [fileIsUploaded, setFileIsUploaded] = useState(false);

    useEffect(() => {
        props.status.code === "VALIDATION_IN_PROGRESS" ? props.setUploadBtnDisabled(true) : props.setUploadBtnDisabled(false)
    }, [props.status])

    const catchErrorAction = (_) => {
        setFileIsUploaded(false);
        props.setUploadBtnDisabled(false);
    }

    const clearFileUploader = (summary, message) => {
        showError(summary, message)
        setFileIsUploaded(false);
        fileUploadRef.current.clear();
    }

    const setNoDataStatus = () => {
        setNoDataToProvide(props.dataCollection.name, props.dataCollection.id, props.marketNode.id, props.marketNode.name, props.category.categoryDTO.name, props.category.id)
            .then(() => {
                props.setValidationMsg("No data to be provided")
                props.findByDCAndMN();
            })
    }

    const getFileContext = (e) => {
        const file = e.files[0];
        if (!(file.name.toLowerCase().endsWith(".xlsx") || file.name.toLowerCase().endsWith(".ttl"))) {
            clearFileUploader("The upload file is not a recognized Excel file or a Turtle file.",
                "The upload file must have '.xlsx' or '.ttl' extension.");
        } else if (file.size > 52428800) {
            clearFileUploader("File is too large to upload",
                "The uploaded file must not exceed 50 MB")
        } else {
            setFileIsUploaded(true);
            props.setUploadBtnDisabled(true);
            setFileName(file.name);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('params', JSON.stringify({
                "categoryName": props.category['categoryDTO'].name,
                "dataCollection": props.dataCollection,
                "marketNode": props.marketNode,
                "marketNodeCategoryId": props.category.id,
                "uploadedBy": keycloak.idTokenParsed.name,
            }))

            uploadFile(formData).then(res => {
                props.setValidationMsg("No data");
                props.setFilePath(res.data.content);
                props.onFileUpload(true);
                setFileIsUploaded(false);
                props.setValidationButtonDisabled(true);
            }).catch(catchErrorAction);
        }
    }
    return fileIsUploaded
        ? (
            <div className="flex align-items-center justify-content-center">
                <Spinner fileName={fileName}/>
            </div>
        )
        : (
            <div className="flex align-items-center justify-content-center">
                <UploadButton dataCollection={props.dataCollection}
                              statusLV4={props.statusLV4}
                              fileUploadRef={fileUploadRef}
                              getFileContext={getFileContext}
                              updateStatus={setNoDataStatus}
                              uploadBtnDisabled={props.uploadBtnDisabled || props.marketNodeDataIsPublishing || fileIsUploaded||props.isValidation5InProgress}
                />

            </div>
        )
}


export default DataUpload;
